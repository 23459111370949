let lyriklist = [
  {
    title: "Führen muss gelernt sein (für Führer Schneider)",
    text: `    
  Recht einfach scheint das Wörtchen „führen“
  Lohnt es sich da, es anzurühren?
  Das tut es, denn es zeigt zum Schluss
  Was unser Führer leisten muss
  
  Zum einen gilt es anzuführen
  Das muss man hier nur kurz berühren
  Denn unter uns ist allen klar:
  Ein Führer ist zum Führen da
  
  Doch gilt es auch zu überführen
  Die Straftat nicht zu tolerieren
  Dem Burschen seine Strafe nennen
  Der Bursche Fatrai wird das kennen
  
  Sodann gilt es heranzuführen
  Die Jungburschen zu inspirieren
  Damit, wenn sie erst inspiriert,
  So mancher auch mal Führer wird
  
  Und zuletzt gilt es einzuführen
  Denn die Balbine will ja spüren
  Was so ein Führer, so ein Mann
  Im Süden alles leisten kann  
`
  },
  {
    title: "Schleifenüberreichung 2019",
    text: `    
    Es haben ja die Burschenschaften
    Fast alle ihre Eigenschaften
    Die jene Menschen, die dort weilen,
    Sich allesamt im Grunde teilen
    
    Zum Beispiel sind die Billerbächer
    Nicht zwingend schlanke Spargelstecher
    Wer so am Tau zu zieh’n vermag
    Wählt stets das Schnitzel, nicht den Quark
    
    Man kann sie deshalb leicht erkennen
    Wenn auch nicht gleich beim Namen nennen
    Passt jemand nicht ins Auto rein
    Wird’s wohl ein Billerbächer sein
    
    Und auch die Bach ist leicht zu sehen
    Sieht man sie traurig dorten stehen 
    Man fragt sich oft: “Was ist passiert?
    Er wirkt so trostlos und verwirrt.”
    
    Er sagt sich eben unablässig:
    “Das Leben bei der Bach ist grässlich!”
    Sieht man nun jemand ständig weinen
    Das ist die Bach, so will ich meinen
    
    Nun muss man sich natürlich fragen:
    Lässt sich denn auch so einfach sagen
    Welch Eigenschaft die Balbach prägt
    Und ihnen ihr Profil auflegt?
    
    Sucht man nach Dingen, die uns einen,
    Dann muss man erstmal viel verneinen
    Zuerst fällt einem nämlich ein
    Was muss hier nicht zwingend sein:
    
    Man muss nicht weise und belesen
    Gebildet sein und seine Thesen
    Rhetorisch sicher wiedergeben
    Um andere zu widerlegen
     Man muss auch nicht in Doppelschichten
    Erst Bier und danach Schnaps vernichten
    Nicht immer beim Staffettentrinken
    Von Stirn bis Kinn im Glas versinken
    
    Man braucht auch keine reichen Eltern
    Muss nicht selbst Wein in Frankreich keltern
    Und sicher nicht mit vollen Händen
    Die größten Literzahlen spenden
    
    Man muss nicht laut sein und in Chören
    Den Ablauf der Versammlung stören
    Man muss nicht auf den Kronenstufen
    Am lautesten von allen rufen
    
    Man muss nicht treu sein, nicht vollkommen
    Wen haben wir schon aufgenommen
    Von dem die Treue, die er schwört,
    Auch anderen hat schon gehört
    
    Es scheint von diesen ganzen Dingen
    Nicht einem wirklich zu gelingen
    Die Balbach unter sich zu einen
    Es ist was and’res, will man meinen
    
    Wer uns verfolgt und stets begleitet,
    Hat eines sicher aufgezeichnet:
    Die Balbach will vor allen Dingen
    Die Menschen zueinander bringen
    
    Sie will, dass zwischen allen Festen
    Die Menschen ihr Gewissen testen
    Zum wahren Guten sich bekennen
    Und uns als ihr Zuhause nennen
    
    Sie will, dass Menschen aller Arten
    Die Stärksten wie die kleine Zarten
    Die Sehenden und auch die Blinden
    Bei uns gelöst zusammenfinden
     Dass Jungen, Mädchen aller Klassen
    Sich fröhlich bei uns niederlassen
    Dass Farben ihrer Haut nicht zählen
    Und Religionen sie nicht quälen
    
    Dass es egal ist, wen sie lieben
    Mit wem sie eine Nachtschicht schieben
    Solange sie in sieben Jahren
    Nur etwas Liebe für uns sparen
    
    Man sieht, dass viele Eigenschaften
    Zu Burschen und Balbinen passen
    Solange sie sich akzeptieren
    Und fröhlich feiernd tolerieren 
    
    Den einen Menschen, den Perfekten
    Von Gottes Zunge glatt geleckten
    Ist weder uns noch den Balbinen
    In all den Jahren je erschienen
    
    Was will man auch mit Perfektionen
    Dann säßen wir nur zwischen Klonen
    Der anderen in diesem Raum
    Und Unterschiede gäb es kaum 
    
    Die Unterschiede, die wir haben,
    Die sind es, die die Balbach tragen,
    Das macht zwar keinen hier vollkommen
    Doch wunderbar für sich genommen
    
    So lässt sich dann am Ende sagen:
    Wir lieben alle, die wir haben,
    Die eine, große Eigenschaft
    Gibts nicht in uns’rer Burschenschaft
    
    Und wenn man schön und trinkfest ist
    Elfmeter auch beim Schlafen trifft
    Und spenden fast sekündlich kommen?
    Dann Glückwunsch! Du bist aufgenommen
 `
  },
  {
    title: "Alternativen",
    text: `    
Nun ist es leider nichts geworden
Das Amt des Wettläufers zu seh'n 
Der Traum ist erst einmal gestorben
Und du musst mit uns allen geh'n

Nun ist es leider nichts geworden
Mit Gigi, der die Peitsche schwingt
Mit Hundertschaften, Menschenhorden,
Die er zum Frühstücksplatze bringt

Nun ist es leider nichts geworden
Kein Ruhm erst mal für 7 Jahr'
Kein Lied, kein Geld und keine Orden
So hässlich steht das Leben da

Was bleibt dir noch von diesem Leben
Was hält dich vorm Verzweifeln ab
Nach was kannst du wohl jetzt noch streben
So kurz vorm ersten Schritt ins Grab

Nun gut, jetzt kannst du weiter saufen
Kannst feiern, während Roemer pennt
Kannst Kippen, Schnaps und Drogen kaufen
Und vögeln, bis die Vorhaut brennt
 `
  },
  {
    title: "Die Saidippe",
    text: `    
    Wir laufen auf der steilen Klippe
    Und uns're geilen Saidippe
    Nehm' alle Müh und Not in Kauf
    Und füll'n die Hopfbestände auf
 `
  },
  {
    title: "Welches Tier gehört zur Bach?",
    text: `    
    Die Qualle ist der Bach zu eigen
    Warum, das will ich gerne zeigen
    Die Antwort ist, falls jemand fragt
    Bei beiden wird rein gar nichts hart
 `
  },
  {
    title: "Ode an die Brust",
    text: `    
    Wenn ich hier so vorne steh'
    Und auf euch Balbinen seh'
    Fällt mir eines sofort auf
    Titten gibt es hier zu Haus
    
    Kleine und auch große Titten
    Solche, die beim Gehen wippen
    Jene, die zum Kneifen zwingen
    Die, die aus dem T-Shirt springen
    
    Die, die spitz nach vorne steh'n
    Die, die in die Breite geh'n
    Die, die sich wie Kissen greifen
    Und die kleinen, harten, steifen
    
    Ach, mir steigen die Gelüste
    Denke ich an eure Brüste
    Und das schönste ist dabei
    Jede von euch hat gleich zwei
 `
  },
  {
    title: "Die Heimkehr",
    text: `    
    Der gute, alte Oberst Stein
    Der fährt mit seinem Wagen heim
    Doch schon am steilen Kottenbach
    Da liegt ein Balbachbursche flach
    
    Deswegen hält der Oberst an
    Und er betrachtet diesen Mann
    Er fragt, als er den Mensch erkennt:
    „Warum hast du denn hier gepennt?“
    
    Und Rainer ist sich völlig sicher:
    „Es war das allerletzte Licher
    Die Flasche Kümmel hat mein Magen
    Bis hierhin ziemlich gut vertragen
    
    Doch dann wurd's heute Nacht so kalt
    Deswegen lag ich auf Asphalt
    Der hat, obwohl er ziemlich hart
    Die Wärme noch vom Tag gespart
    
    Ich wollt' mich nur kurz niederlegen
    Nur um mir dann zu überlegen
    Geb' ich mir Mühe und steh' auf
    Dann denk ich: Rainer, scheiß doch drauf“
    
    Da sagt der Oberst unser'm Rainer
    „So voll wie du war heute keiner
    In deinen fortgeschritt'nen Jahren
    Sollt' ich dich wohl nach Hause fahren“
    
    Und Rainer denkt sich: „Erste Sahne
    Der wird sich freu'n bei meiner Fahne
    So kommt auch Rainer, trotz der Pause
    Am Ende doch noch heil nach Hause
 `
  },
  {
    title: "Vorfreude",
    text: `    
    Ich grüße euch, ihr Hainstraß'-Männer
    Und eure Frau'n, die frommen Lämmer
    Ich möchte auch die Burschen grüßen
    Und die Balbinen, uns're Süßen
    
    Heut' am Kommers sind wir bereit
    Für eine schöne Grenzgangszeit
    Man hört schon fast das Peitschenknallen
    Aus uns'ren schönen Wäldern knallen
    
    Heut' Abend stimmen wir uns ein
    Um morgen dann bereit zu sein
    Am Marktplatz vorfreudig zu steh'n
    Um dann den Grenzgang zu begeh'n
    
    Und was hab'n wir schon geschafft
    Denn alles hat sich aufgerafft
    Um ohne Schandfleck oder Lücken
    Die ganze Hainstraße zu schmücken
    
    Dort unten in der Tennisaue
    Da standen dutzende von Fraue'
    Die schwer geackert, sich geschunden
    Und die Girlanden uns gebunden
    Die Männer sprangen durch den Wald
    Dass in der Hainstraße alsbald
    Wenn denn der Grenzgang schließlich geht
    Auch überall 'ne Birke steht
    
    Die ganze Stadt hat schon gewonnen
    Doch kann es jetzt noch schöner kommen
    Da doch die Stadt schon ausgeschmückt
    Und uns damit ins Glück entrückt
    
    Da doch das Bier schon munter fließt
    Und sich in unser'n Schlund ergießt
    Da doch die Sonne prächtig scheint
    Und uns hier unten froh vereint
    
    Balbinen stehen in den Gassen
    Und könn' nicht von den Burschen lassen
    So wie die Stadt, sind sie perfekt
    Und sehen aus wie frisch geleckt
    
    Doch kann es jetzt noch schöner kommen?
    Ja! Eines wurd' noch nicht ersonnen
    Denn aus den tiefen, blauen Wogen
    Da kommt ein Wal herbeigezogen
    
    Wenn er dann da ist, feiern wir
    Auf ihn erheben wir das Bier
    Vor Freude wird herumgehüpft
    Denn unser Jony ist geschlüpft
    
    Die Flosse steht zum Himmel rauf
    Und die Balbinen drehen auf
    Seh'n sie den Jonas auf der Fahrt
    Wird ihnen gleich der Nippel hart
    
    Dem Torben, unser'm Kasimir
    Platzt fast die Hose voller Gier
    Denn, wenn wir Jungs den Jonas seh'n
    Haben wir sofort einen steh'n
    
    Denn keiner hat ein solches Tier
    Und keiner geht so steil wie wir
    Nicht einer macht so geilen Krach
    Und ganz besonders nicht die Bach
    
    Ja, denn die Bach ist eine Qualle
    Für die Bekloppten sind wir alle
    Seit jeher eine solche Qual
    Sie haben nichts, wir den Pokal
    
    Doch lasst uns mit der Bach hier enden
    Wer will damit schon Zeit verschwenden
    Wir nutzen lieber uns're Zeit
    Mit Feiern und Geselligkeit
    
    So hebt die Gläser und stoßt an
    Auf jede Frau und jeden Mann
    Auf Freude, Spaß und Heiterkeit
    Und eine geile Grenzgangszeit
 `
  },
  {
    title: "Marschübung",
    text: `    
Will man heute gut marschieren
Muss man auch dafür trainieren
Denkt man noch ein wenig weiter
Braucht man 'nen Marschübungsleiter

Dieser kommt hochdekoriert
Was jeden Burschen fasziniert
Mit seinem Jeep herangeschossen
Als wär' der Krieg g'rad ausgebrochen

Die Burschenschaft nimmt Stellung auf
Doch and'res hat sie besser drauf
Denn noch steht alles Kreuz und quer
Und macht von Kopf bis Fuß nichts her

Um sie nicht weiter zu blamieren
Fängt Kunkler an zu lamentieren
Und zwischen seinen grellen Schreien
Vernimmt man was von Dreierreihen

Nachdem ein wenig Zeit vergeht
Und alles halbwegs richtig steht
Dreht Kunkler auf und kommentiert
Fast alles, was ab jetzt passiert:

„Ihr Burschen sollt nach vorne gucken
Und nichtmal mit den Wimpern zucken
Wenn euch der Weihnachtsmann begegnet
Und es vom Himmel Muschis regnet

Und fang ich an zu onanieren
Dürft ihr auch nur nach vorne stieren“
Dies lässt die Burschenschaft so steh'n
Denn das will wirklich keiner seh'n

Dann geht es los mit Marschgeschrei
Und links und rechts und eins, zwei, drei
Das macht zum Großteil schon was her
Wenn mancher Bursche doch nicht wär

Denn bei den gleichen Herr'n wie immer
Wird es nicht besser, es wir schlimmer
Und Ferchland, Achenbach und Weiß
Sie treten kurz aus unser'm Kreis

Für sie folgt nebst der Ehrenrunde
Auch eine kleine Einzelstunde
So dass selbst diese drei kapieren
Wie das denn geht mit dem Marschieren

Das Ende scheint's schon fast zu sein
Da trudelt UDW auch ein
Auch wenn er sich natürlich ziert
Wird er von Kunkler einkassiert

Schon fängt der Übungsleiter an:
„Du läufst jetzt in die Richtung man
Um dann, auch wenn die Füße rauchen
Auch dieser wieder aufzutauchen“

Bei den Balbinen wird gekichert
Sie fühlen sich wohl abgesichert
Sie irren sich natürlich, Schade
Denn Kunkler ist kein Mann der Gnade

Das, was nun folgt von dieser Bande
Es ist noch keine große Schande
Doch gegen diesen Entenlauf
Sieht selbst der Achenbach gut aus

Naja, der ganze Hühnerhaufen
Muss ja beim Grenzgang auch nicht laufen
Doch sage ich in stolzem Ton
Die Burschenschaft, die schafft das schon
 `
  },
  {
    title: "Jonas",
    text: `    
Der Wal ist unser Wappentier
Der Jonas, dem wir alle hier
Aus tiefer Seele Ehrfurcht zollen
Und ihm die Treue schwören sollen

In der Geschichte ist es klar
Was einst der Ausschlaggeber war
Der da den Wal zur Balbach brachte
Und ihn zu unser'm Schirmherr machte

Ich sage es nochmal genauer
Das Walfischbräu der Balbach-Brauer
Das hat damals dazu geführt
Dass Jonas uns're Fahne ziert

Doch hab' ich nochmal nachgehakt
Was gibt’s, das hab' ich mich gefragt
Das wir seit eben jenen Tagen
Wohl mit dem Wal gemeinsam haben

Bei uns, den Burschen, ist gewiss
Was zum Wal die Verbindung ist
Es ist die Kunst, das kann man sehn'
Mit dicken Schwänzen umzugehn'

Doch bei den Mädels ist es schwer
Wo kommt da die Verbindung her
Sieht man den Wal, den dicken Brecher
Denkt man zunächst an Billerbächer

Doch sieht man ihn im Wasser gleiten
Dann ist er in den blauen Weiten
Wie die Balbinen anzusehn'
Elegant und traumhaft schön

Und wie der Walfisch unbeirrt
Stets zum Geburtsort wiederkehrt
Kehrt bei der Balbach groß und klein
Zum Grenzgang immer wieder heim

Ihr seht schon, euer Wappentier
Ist fast so wichtig wie das Bier
Dass jedem hier so sehr gefällt
Und uns zum Teil zusammenhält

Ihr solltet immer daran denken
Dem Jonas euer Herz zu schenken
Gebt ihm die Treue und das Glück
Ganz sicher zahlt er es zurück
    `
  },
  {
    title: "125 Jahre Balbach",
    text: `    Der Mensch, er wird allein geboren
    Und geht allein aus dieser Welt 
    Er ist an jedem neuen Morgen
    Erneut auf sich allein gestellt

    Doch ist er auch Gemeinschaftswesen
    Das kann man hier besonders seh'n 
    Wo nah zum Dappesboden-Thresen
    Die Hundertschaften Schlange steh'n
    
    Drum fragen Menschen sich schon lange
    Die Frau, das Kind und auch der Mann 
    Wo man solch eine Menschenschlange 
    Am besten unterbringen kann 
    
    Vor vielen Zeiten war's gegeben
    Dass man sich in der Kirche traf
    Doch unter Gottes Blick zu leben
    Da muss man still sein und recht brav
    
    Das ist dem jungen Biedenköpfer
    Das Letzte, was er sein will 
    Es machte sie der große Schöpfer
    Nur selten brav – und niemals still 
    
    So muss ein Ort gefunden werden
    Wo sich der junge Mann gebärt
    Trinkt kühles Bier an warmen Herden
    Und seine alten Bräuche ehrt
    
    Die Burschenschaften sollen diesen
    Den jungen Männern dieser Stadt
    Ein Leben unter gleichen bieten 
    Denn dort erst findet Leben statt 
    
    So denkt der Mann in diesen Zeiten
    Und nimmt für sein Geschlecht heraus
    Die Mädchen dürfen nicht begleiten
    Man schließt sie anfänglich noch aus
    
    Da sind wir heute deutlich weiter
    Und kommen stetig mehr voran
    Denn auf der Sprosse uns'rer Leiter
    Steh'n Frauen bald gleichauf zum Mann
    
    Doch damals war das Zukunftsdenken
    Und böse war es nie gemeint
    Drum lasst den alten Burschen schenken
    Die unsrige Ergebenheit 
    
    So lasst uns kurz nur fernwärts schweifen
    In Zeiten, die man nicht mehr kennt
    Als Menschen noch nach Pferden pfeifen
    Und man den Kaiser heilig nennt
    
    In Zeiten, da die Welt im Ganzen
    Im nächsten Dorf zu Ende scheint 
    Da Paare noch zu Mozart tanzen
    Und nachts nicht eine Lampe scheint
    
    Man lebt in schrecklich schönen Jahren
    Der Schrecken ist der Hungertod
    Die Menschen dieser Zeiten waren 
    Nicht immer fern von aller Not 
    
    In achtzehndreiundneunzig fehlen 
    Den Bürgern Brot und vieles mehr 
    Drum muss man das Jahr später wählen
    Aus diesem stammt die Balbach her
    
    Die Hainstraße ist gut vertreten
    Und bringt mit Unkel und auch Schmitt
    Die damals auf die Grenze treten
    Bereits zwei Burschenschaften mit 
    
    Doch drei sind all der guten Dinge
    Drum fehlt noch eins zum großen Glück
    Der dritten wird der Wurf gelingen
    Sie bleibt als einzige zurück 
    
    Es treffen also sich die Jungen
    An diesem uns so fernen Tag
    Doch ihnen ist es nicht gelungen
    Zu sagen, wie man heißen mag 
    
    Da hilft ein wohlbekannter Tropfen 
    Der schaumig schön vor ihnen steht
    Gebraut aus Wasser, Malz und Hopfen
    Und auf dem deutlich „Balbach“ steht 
    
    Das Walfischbräu der Balbachbrauer
    Gibt nicht nur seinen Namen her
    Der Jonas ist seitdem auf Dauer
    Der Balbachburschen Wappentier 
    
    Der Wal wird bald zu der Ikone
    Die er bis heut' geblieben ist 
    Und nie mehr geht ein Grenzgang ohne
    Den Jonas raus, wie ihr wohl wisst
    
    Der Wal ist uns ein Freund geworden
    Ein Leitstern, der uns alle führt
    An jedem neuen Grenzgangsmorgen 
    Wird unser Herz von ihm berührt
    
    Er wird beneidet, wird gestohlen
    Wird oft mit bösem Rot beschmiert
    Nur um ihn dann zurückzuholen
    So dass sein Schwarz ihn wieder ziert
    
    Er ist es, den die Burschen fanden 
    Vor einer halben Ewigkeit
    Auf Bechern, die am Tische standen
    Mit Bier, das seinen Namen leiht 
    
    So schickt zu der Jahrhundertwende
    Die alte Hainstraße schon drei
    Der Burschenschaften ins Gelände
    Und größer sind die and'ren zwei 
    
    Es ändern sich die Zahlen eilig
    Mit Schmidt und Unkel geht es aus 
    Doch nimmt die Balbach diese freilich
    Sehr gerne bei sich selber auf 
    
    So geh'n die Jahre wie die Burschen 
    Ein Grenzgang folgt dem anderen 
    Gesichter kriegen tiefe Furchen 
    Doch immer gibt die Balbach Sinn
    
    Es soll ein Höhepunkt noch kommen
    Denn eines fehlt zum wahren Glück
    Noch ist die Balbach nicht vollkommen
    Es fehlt noch das Balbinen-Stück
    
    Es war vor beinah 50 Jahren 
    Dass man die Mädchen zu sich nimmt
    Die fortan unser Glanzstück waren
    Und es bis heut' geblieben sind 
    
    Und seien es nun die Balbinen
    Sei'n es die Burschen, jung und alt 
    Ein jeder mag dem Walfisch dienen
    Er ist die oberste Gewalt 
    
    Die Balbach wird es weiter geben
    Weil Menschen ihr ergeben sind 
    Ihr ist, auch wenn wir nicht mehr leben,
    Die Ewigkeit vorherbestimmt 
    
    So hebt die Gläser auf das Wahre
    Auf herrliche Vergangenheit 
    Auf hundertfünfundzwanzig Jahre 
    Und auf die zukünftige Zeit `
  }
];

export { lyriklist };
