function sortMe(arr, byWhat, upOrDown) {
  // Zunächst wird gecheckt, ob im Array überhaupt Elemente vorhanden sind. Zum einen, weil damit Errors vermieden werden, zum anderen macht es keinen Sinn, ein leeres Object zu sortieren
  if (arr.length > 0) {
    let objIndex, newArr;
    // Je nachdem nach was sortiert wird, wird der Index gestellt. Da es ein Arrays aus Objekten ist; Nachname an Position 2, Literzahl an Position 3
    byWhat === "Name" ? (objIndex = "lastName") : (objIndex = "openLiters");
    // Nun wird über das Array iteriert
    arr.forEach(function (item, index) {
      let ind;
      if (index === 0) {
        ind = 1;
      } else {
        ind = index;
      }
      if (upOrDown === "+") {
        if (objIndex === "lastName") {
          // Wenn der erste Buchstabe des Namens "kleiner" als der des vorherigen Namens ist, ist alles richtig durch das false wird nicht in die If-Schleife gegangen, ansonsten wird sortiert: Beispiel: Gemmecke > Schneider = false => da dies stimmt kann man weitergehen. Andernfalls wird die Stelle ausgeschnitten und getauscht
          if (arr[ind - 1][objIndex][0] > arr[ind][objIndex][0]) {
            let cutOut = arr.splice(ind - 1, 1); // schneide das Element des jeweiligen Index aus
            newArr = arr.splice(ind, 0, cutOut[0]); // füge es an der Stelle danach ein
          }
        } else {
          if (arr[ind - 1][objIndex][0] < arr[ind][objIndex][0]) {
            let cutOut = arr.splice(ind - 1, 1); // schneide das Element des jeweiligen Index aus
            newArr = arr.splice(ind, 0, cutOut[0]); // füge es an der Stelle danach ein
          }
        }
      } else if (upOrDown === "-") {
        // Die Logik ist in dem Fall exakt umgekehrt
        if (objIndex === "lastName") {
          if (arr[ind - 1][objIndex][0] < arr[ind][objIndex][0]) {
            let cutOut = arr.splice(ind - 1, 1);
            newArr = arr.splice(ind, 0, cutOut[0]);
          }
        } else {
          if (arr[ind - 1][objIndex][0] > arr[ind][objIndex][0]) {
            let cutOut = arr.splice(ind - 1, 1);
            newArr = arr.splice(ind, 0, cutOut[0]);
          }
        }
      }
    });
    return newArr;
  }
}

function compareNames(a, b) {
  // Use toUpperCase() to ignore character casing
  const genreA = a.lastName.toUpperCase();
  const genreB = b.lastName.toUpperCase();

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison;
}

function compareNegativeNames(a, b) {
  // Use toUpperCase() to ignore character casing
  const genreA = a.lastName.toUpperCase();
  const genreB = b.lastName.toUpperCase();

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison * -1;
}

function compareLiters(a, b) {
  // Use toUpperCase() to ignore character casing
  const genreA = a.openLiters;
  const genreB = b.openLiters;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison;
}

function compareNegativeLiters(a, b) {
  // Use toUpperCase() to ignore character casing
  const genreA = a.openLiters;
  const genreB = b.openLiters;

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison * -1;
}

function validate(email) {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([x01-x08x0bx0cx0e-x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[x01-x09x0bx0cx0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email).toLowerCase());
}

export {
  sortMe,
  compareNames,
  compareNegativeNames,
  compareLiters,
  compareNegativeLiters,
  validate,
};
