import React, { Component } from "react";
import "./footer.css";
import { IoMdHeartEmpty } from "react-icons/io";

export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="footer">
          <a className="footer-link" href="/datenschutz">
            Datenschutz
          </a>
        </div>
        <div className="bottenhorn">
          Made with{"  "}
          <p className="heart_icon">
            <IoMdHeartEmpty />
          </p>
          {"  "}
          in Bottenhorn
        </div>
      </React.Fragment>
    );
  }
}

export default Footer;
