import React, { Component } from "react";
import "./historie.css";

import { history } from "./history-list";

export class Historie extends Component {
  render() {
    return (
      <div className="history">
        <h1 className="history-heading">Historie der Balbach</h1>
        <p className="subheading-history">
          Swipe nach links und rechts um zwischen den historischen Grenzgängen
          der Balbach zu navigieren.
        </p>
        <div className="history-list">
          {history.map(item => {
            return (
              <div className="history-item">
                <h2 className="history-heading">{item.heading}</h2>
                {item.image ? (
                  <img
                    src={item.image}
                    alt={`Die Schleife zum ${item.heading}`}
                    className="schleife"
                  />
                ) : null}
                {item.inPersona.map(subItem => {
                  return (
                    <div>
                      <h3 className="history-position">{subItem.position}</h3>
                      {subItem.names.map(name => (
                        <p className="history-names">{name}</p>
                      ))}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Historie;
