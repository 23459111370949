import React, { Component } from "react";
import "./intern.css";
import AuthContext from "../context/auth-context";
import BurschenIntern from "../Body/BurschenIntern";
import BalbinenIntern from "../Body/BalbinenIntern";

export class Intern extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.newFirstName = React.createRef();
    this.newLastName = React.createRef();
    this.newLiter = React.createRef();
  }

  state = {
    visibleBurschen: true,
    visibleAddField: false,
    burschenName: 0,
    burschenLit: 0,
    balbinenName: 0,
    balbinenLit: 0,
  };

  addNew = () => {
    let type;
    this.state.visibleBurschen ? (type = "Bursche") : (type = "Balbine");
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                mutation {
                    new${type}(
                    input:
                    {
                        firstName: "${this.newFirstName.current.value}" 
                        lastName:"${this.newLastName.current.value}" 
                        openLiters: ${this.newLiter.current.value}
                    }) 
                    {
                        firstName
                    }
                }
              `,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((res) => res.json())
      .then(window.location.reload.bind(window.location))
      .catch((err) => {
        //  console.log(err)
      });
  };

  changeComponent = (type) => {
    if (type === "Burschen") {
      this.setState({ visibleBurschen: true }, () => {
        localStorage.setItem(
          "adminState",
          JSON.stringify({
            visibleBurschen: this.state.visibleBurschen,
            visibleAddField: this.state.visibleAddField,
          })
        );
      });
    } else {
      this.setState({ visibleBurschen: false }, () => {
        localStorage.setItem(
          "adminState",
          JSON.stringify({
            visibleBurschen: this.state.visibleBurschen,
            visibleAddField: this.state.visibleAddField,
          })
        );
      });
    }
  };

  componentWillMount() {
    const rehyAdminSite = JSON.parse(localStorage.getItem("adminState"));
    this.setState(rehyAdminSite);
  }

  sortBy = (type, nameOlit) => {
    if (type === "Bursche") {
      if (nameOlit === "Name" && this.state.burschenName !== 1) {
        this.setState({ burschenName: 1 }, () => {
          this.child.srtbby(1, "Name");
        });
      } else if (nameOlit === "Name" && this.state.burschenName === 1) {
        this.setState({ burschenName: 0 }, () => {
          this.child.srtbby(0, "Name");
        });
      } else if (nameOlit === "Litern" && this.state.burschenLit !== 1) {
        this.setState({ burschenLit: 1 }, () => {
          this.child.srtbby(1, "Litern");
        });
      } else if (nameOlit === "Litern" && this.state.burschenLit === 1) {
        this.setState({ burschenLit: 0 }, () => {
          this.child.srtbby(0, "Litern");
        });
      }
    } else {
      if (nameOlit === "Name" && this.state.balbinenName !== 1) {
        this.setState({ balbinenName: 1 }, () => {
          this.childin.srtbby(1, "Name");
        });
      } else if (nameOlit === "Name" && this.state.balbinenName === 1) {
        this.setState({ balbinenName: 0 }, () => {
          this.childin.srtbby(0, "Name");
        });
      } else if (nameOlit === "Litern" && this.state.balbinenLit !== 1) {
        this.setState({ balbinenLit: 1 }, () => {
          this.childin.srtbby(1, "Litern");
        });
      } else if (nameOlit === "Litern" && this.state.balbinenLit === 1) {
        this.setState({ balbinenLit: 0 }, () => {
          this.childin.srtbby(0, "Litern");
        });
      }
    }
  };

  render() {
    const {
      visibleAddField,
      burschenName,
      burschenLit,
      balbinenName,
      balbinenLit,
    } = this.state;
    let burschenSortNameSymbol,
      burschenSortLiterSymbol,
      balbinenSortNameSymbol,
      balbinenSortLiterSymbol;
    burschenName === 1
      ? (burschenSortNameSymbol = "△")
      : (burschenSortNameSymbol = "▽");
    burschenLit === 1
      ? (burschenSortLiterSymbol = "△")
      : (burschenSortLiterSymbol = "▽");
    balbinenName === 1
      ? (balbinenSortNameSymbol = "△")
      : (balbinenSortNameSymbol = "▽");
    balbinenLit === 1
      ? (balbinenSortLiterSymbol = "△")
      : (balbinenSortLiterSymbol = "▽");
    return (
      <div className="intern">
        <h1>Balbach-Intern</h1>
        <div className="switch-buttons">
          <button
            onClick={() => this.changeComponent("Burschen")}
            className="switch-button"
          >
            Burschen
          </button>
          <button
            onClick={() => this.changeComponent("Balbinen")}
            className="switch-button"
          >
            Balbinen
          </button>
        </div>

        {this.context.proToken && this.state.visibleBurschen && (
          <div className="admin-buttons">
            <button
              onClick={() => this.setState({ visibleAddField: true })}
              className="add-new"
            >
              Burschen hinzufügen
            </button>
          </div>
        )}
        {this.context.proToken && !this.state.visibleBurschen && (
          <div className="admin-buttons">
            <button
              onClick={() => this.setState({ visibleAddField: true })}
              className="add-new"
            >
              Balbine hinzufügen
            </button>
          </div>
        )}
        {visibleAddField && (
          <div className="addfield">
            <input type="text" placeholder="Vorname" ref={this.newFirstName} />
            <input type="text" placeholder="Nachname" ref={this.newLastName} />
            <input type="number" placeholder="Literzahl" ref={this.newLiter} />
            <div className="addBtnBox">
              <button className="add-new add-btn" onClick={() => this.addNew()}>
                Hinzufügen
              </button>
              <button
                className="close-btn"
                onClick={() => this.setState({ visibleAddField: false })}
              >
                Abbrechen
              </button>
            </div>
          </div>
        )}
        <BurschenIntern
          sortBy={(arr, byWhat) => this.sortBy(arr, byWhat)}
          visible={this.state.visibleBurschen}
          nameSymb={burschenSortNameSymbol}
          literSymb={burschenSortLiterSymbol}
          ref={(instance) => {
            this.child = instance;
          }}
          burschenName
          burschenLit
        />
        <BalbinenIntern
          sortBy={(arr, byWhat) => this.sortBy(arr, byWhat)}
          visible={!this.state.visibleBurschen}
          nameSymb={balbinenSortNameSymbol}
          ref={(instance) => {
            this.childin = instance;
          }}
          literSymb={balbinenSortLiterSymbol}
          balbinenName
          balbinenLit
        />
      </div>
    );
  }
}

export default Intern;
