import React, { Component } from "react";
import "./login.css";
import AuthContext from "../context/auth-context";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.userName = React.createRef();
    this.password = React.createRef();
    this.vorstandsUserName = React.createRef();
    this.vorstandsPassword = React.createRef();
  }

  state = {
    denied: false,
    deniedFuehrer: false,
  };

  static contextType = AuthContext;

  handleFormSubmit = (e, type) => {
    e.preventDefault();
    if (type === "BurscheBalbine") {
      fetch(`${process.env.REACT_APP_URL}`, {
        method: "POST",
        body: JSON.stringify({
          query: `
            query {
              login(
                name: "${this.userName.current.value}", password: "${this.password.current.value}"
              ) 
              {
                token
                tokenExpiration
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.hasOwnProperty("errors")) {
            this.setState({ denied: true });
            return false;
          }
          if (resData.data.login.token) {
            this.setState({ denied: false });
            this.context.login(resData.data.login.token);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      fetch(`${process.env.REACT_APP_URL}`, {
        method: "POST",
        body: JSON.stringify({
          query: `
            query {
              vorstandsLogin(
                name: "${this.vorstandsUserName.current.value}", password: "${this.vorstandsPassword.current.value}"
              ) 
              {
                token
                tokenExpiration
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((resData) => {
          if (resData.hasOwnProperty("errors")) {
            this.setState({ deniedFuehrer: true });
            return false;
          }
          if (resData.data.vorstandsLogin.token) {
            this.setState({ deniedFuehrer: false });
            this.context.adminLogin(resData.data.vorstandsLogin.token);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  render() {
    return (
      <div className="login">
        <h2>Login</h2>
        <p>Burschen/Balbinen-Login</p>
        <form className="login-box">
          <input type="text" placeholder="Nutzername" ref={this.userName} />
          <input type="password" placeholder="Passwort" ref={this.password} />
          <br />
          <input
            onClick={(e) => this.handleFormSubmit(e, "BurscheBalbine")}
            className="login-submit"
            type="submit"
            value="Einloggen"
          />
        </form>
        {this.state.denied && (
          <p className="perm-denied">
            Sie haben keine Berechtigung auf diese Seite zuzugreifen.
          </p>
        )}
        <p id="vorst-login">Vorstands-Login</p>
        <form className="login-box">
          <input
            type="text"
            placeholder="Nutzername"
            ref={this.vorstandsUserName}
          />
          <input
            type="password"
            placeholder="Passwort"
            ref={this.vorstandsPassword}
          />
          <br />
          <input
            onClick={(e) => this.handleFormSubmit(e, "Vorstand")}
            className="login-submit"
            type="submit"
            value="Einloggen"
          />
        </form>
        {this.state.deniedFuehrer && (
          <p className="perm-denied">
            Sie haben keine Berechtigung auf diese Seite zuzugreifen.
          </p>
        )}
      </div>
    );
  }
}

export default Login;
