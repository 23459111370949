import React, { Component } from "react";

export class LyrikItem extends Component {
  render() {
    const { lyrik } = this.props;
    return (
      <div className="single-news">
        <section className="lyrik-sec">
          <h4 className="lyrik-title news-title">{lyrik.title}</h4>
          <p className="news-text poesie">{lyrik.text}</p>
        </section>
      </div>
    );
  }
}

export default LyrikItem;
