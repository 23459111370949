import React, { Component } from "react";
import "./burschen.css";

import { burschen } from "./amtstraeger";

export class Burschen extends Component {
  render() {
    return (
      <div className="amtstraeger-19">
        <h1>Amtsträger 2019</h1>
        {burschen.map(item => {
          return (
            <React.Fragment>
              <h2>{item.heading}</h2>
              <div className="amtstraeger-row">
                {item.inPersona.map(subItem => {
                  return (
                    <div className="single-amtstraeger">
                      <img
                        src={subItem.image}
                        alt={`Bild von ${subItem.name}`}
                      />
                      <p className="amtstraeger-name">{subItem.name}</p>
                      <p className="position">{subItem.position}</p>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

export default Burschen;
