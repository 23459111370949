import profile from "../assets/profile.jpeg";
import marlene from "../assets/Marlene_Milchsack.jpg";
import maria from "../assets/Maria_Timm.jpg";
import laetitia from "../assets/Laetitia_Loeffler.jpg";
import julia from "../assets/Julia_Wehn.jpg";
import josephine from "../assets/Josephine_Timm.jpg";
import jana from "../assets/Jana_Wickenhoefer.jpg";
import hannah from "../assets/Hannah_Wiemers.jpg";
import maren from "../assets/mschneider.jpg";
import jonas from "../assets/jbodenbender.jpg";
import bastian from "../assets/cbastian.jpg";
import scholz from "../assets/fscholz.jpg";
import felixliebetrau from "../assets/fliebetrau.jpg";
import torben from "../assets/tzaun.jpg";
import felix from "../assets/fmichel.jpeg";
import maik from "../assets/mwehn.jpg";
import pauler from "../assets/mpauler.jpg";
import fatrai from "../assets/jfatrai.jpg";
import rene from "../assets/rschneider.jpg";
import rainer from "../assets/rkunkel.jpg";
import nico from "../assets/npfeiffer.jpg";
import david from "../assets/dsemmler.jpg";
import julius from "../assets/jbrunner.jpg";
//import tim from "../assets/reiterlichtenthaeler.jpeg";

let burschen = [
  {
    heading: "Adjutanten des Burschenoberst",
    inPersona: [
      {
        image: profile,
        position: "Adjutant des Burschenoberst",
        name: "Tim Lichtenthäler",
      },
      {
        image: felix,
        position: "Adjutant des Burschenoberst",
        name: "Felix Michel",
      },
    ],
  },
  {
    heading: "Führer",
    inPersona: [
      {
        image: jonas,
        position: "1. Führer",
        name: "Jonas Bodenbender",
      },
      {
        image: torben,
        position: "2. Führer",
        name: "Torben Zaun",
      },
      {
        image: rene,
        position: "3. Führer",
        name: "René Schneider",
      },
      {
        image: felixliebetrau,
        position: "4. Führer",
        name: "Felix Liebetrau",
      },
    ],
  },
  {
    heading: "Reiter",
    inPersona: [
      {
        image: profile,
        position: "Reiter",
        name: "Ben Clös",
      },
      {
        image: profile,
        position: "Reiter",
        name: "Robin Ruf",
      },
    ],
  },
  {
    heading: "Schriftführer",
    inPersona: [
      {
        image: profile,
        position: "1. Schriftführer",
        name: "Jean Dietzel",
      },
      {
        image: scholz,
        position: "2. Schriftführer",
        name: "Felix Scholz",
      },
      {
        image: profile,
        position: "3. Schriftführer",
        name: "Christian Achenbach",
      },
    ],
  },
  {
    heading: "Rechner",
    inPersona: [
      {
        image: julius,
        position: "1. Rechner",
        name: "Julius Brunner",
      },
      {
        image: profile,
        position: "2. Rechner",
        name: "Alexander Schneider",
      },
      {
        image: profile,
        position: "3. Rechner",
        name: "Tristan Willershäuser",
      },
      {
        image: profile,
        position: "4. Rechner",
        name: "Hayrettin Taskiran",
      },
    ],
  },
  {
    heading: "Alterspräsident",
    inPersona: [
      {
        image: rainer,
        position: "Alterspräsident",
        name: "Rainer Kunkel",
      },
    ],
  },
  {
    heading: "Mädchenführer",
    inPersona: [
      {
        image: maik,
        position: "Mädchenführer",
        name: "Maik Wehn",
      },
    ],
  },
  {
    heading: "Fahnenträger",
    inPersona: [
      {
        image: profile,
        position: "Fahnenträger",
        name: "Christian Achenbach",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Leonard Metzger",
      },
      {
        image: nico,
        position: "Fahnenträger",
        name: "Nico Pfeiffer",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Sascha Krellig",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Dennis Rakowski",
      },
      {
        image: david,
        position: "Fahnenträger",
        name: "David Semmler",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Dominik Geiss",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Moritz Bögel",
      },
      {
        image: profile,
        position: "Fahnenträger",
        name: "Lucas Fatrai",
      },
    ],
  },
  {
    heading: "Fassmeister",
    inPersona: [
      {
        image: bastian,
        position: "Fassmeister",
        name: "Christopher Bastian",
      },
      {
        image: pauler,
        position: "Fassmeister",
        name: "Martin Pauler",
      },
      {
        image: fatrai,
        position: "Fassmeister",
        name: "Jens Fatrai",
      },
      {
        image: profile,
        position: "Fassmeister",
        name: "Max Crispens",
      },
    ],
  },
];

let balbinen = [
  {
    heading: "Führerinnen",
    inPersona: [
      {
        image: maria,
        position: "1. Führerin",
        name: "Maria Timm",
      },
      {
        image: jana,
        position: "2. Führerin",
        name: "Jana Wickenhöfer",
      },
      {
        image: laetitia,
        position: "3. Führerin",
        name: "Laetitia Löffler",
      },
      {
        image: marlene,
        position: "4. Führerin",
        name: "Marlene Milchsack",
      },
    ],
  },
  {
    heading: "Schriftführerinnen",
    inPersona: [
      {
        image: hannah,
        position: "1. Schriftführerin",
        name: "Hannah Wiemers",
      },
      {
        image: maren,
        position: "2. Schriftführerin",
        name: "Maren Schneider",
      },
    ],
  },
  {
    heading: "Rechnerinnen",
    inPersona: [
      {
        image: josephine,
        position: "1. Rechnerin",
        name: "Josephine Timm",
      },
      {
        image: julia,
        position: "2. Rechnerin",
        name: "Julia Wehn",
      },
    ],
  },
];

export { burschen, balbinen };
