import React, { Component } from "react";
import "./geschichte.css";

export class Geschichte extends Component {
  render() {
    return (
      <div className="geschichte">
        <h1>Gründungsgeschichte</h1>
        <p>
          Am 16. Juni 1894 trafen sich Louis Plitt, Georg Wehn, Karl Zimmermann,
          Heinrich Wild und Karl Wehn in der "Balbachschen Wirtschaft", um eine
          Burschenschaft Balbach zu gründen.
          <br />
          <br />
          Schon 1893 war eine Burschenschaft Balbach ins Leben gerufen worden.
          Aber der Grenzgang wurde dann wegen einer Hungersnot um ein Jahr
          verschoben.
          <br />
          <br />
          Am 19. Juni 1894 gründeten insgesamt 15 Burschen die Burschenschaft
          Balbach.
          <br />
          <br />
          In der ersten Versammlung am 22. Juni wurden Wilhelm Schmidt als
          Vorsitzender, Karl Plitt als Rechner und Karl Wehn als Schriftführer
          gewählt. Der Vorsitzende hatte damals die Aufgabe die Versammlung zu
          leiten, die als "ordentliche Sitzung" bezeichnet wurde. Dabei wurden
          auch Protokolle erstellt, während die "kameradschaftlichen Abende"
          ohne Protokollführung abgehalten wurden.
          <br />
          <br />
          Zu der zweiten Versammlung erschienen 20 Burschen. Sie wählten Louis
          Plitt zum ersten und Georg Wehn zum zweiten Führer.
          <br />
          <br />
          Die Fahne wurde bei Maler Theo Plitt in Auftrag gegeben und als
          Fahnenträger Ferdinant Gerhard für den ersten Tag, Karl Weigel für den
          zweiten Tag und Heinrich Wild für den dritten Tag gewählt.
          <br />
          <br />
          Reiter und gleichzeitig Adjutanten des Burschenobersten waren Adolf
          Schmidt und Wilhelm Braun.
          <br />
          <br />
          Am 7. August, zur neunten Versammlung, lieferte Maler Plitt die Fahne.
          Die Fahnenstange stifteten Carl Zimmermann, Heinrich Wild und Georg
          Nord.
          <br />
          <br />
          Zum Grenzgang 1894 hatte die Burschenschaft Balbach 24 Burschen und
          wurde offiziell unter dem Namen Burschenschaft Richard Balbach
          geführt.
          <br />
          <br />
          In der Hainstraße gab es schon zum Grenzgang 1886 zwei
          Burschenschaften. Es waren die Burschenschaften Heinrich Schmidt III.
          und Johann Peter Unkel.
          <br />
          <br />
          Heinrich Schmidt III. hatte 53 Burschen und 3 Führer. 1894 waren es
          dann noch 32 Burschen mit 2 Führern. Zu späteren Grenzgängen gab es
          diese Burschenschaft dann nicht mehr.
          <br />
          <br />
          Die Burschenschaft Johann Peter Unkel war am Grenzgang 1886 mit 72
          Burschen und 3 Führern vertreten. 1894 ging sie unter dem Namen Carl
          Schmidt mit 83 Burschen und 4 Führern über die Grenze.
          <br />
          <br />
          Zum Grenzgang 1900 war der Name dann in Karl Schmidt geändert, und 76
          Burschen und 3 Führer gingen über die Grenze. Der Grenzgang 1907, mit
          65 Burschen und 3 Führern, war dann bis heute auch der letzte für
          diese bis dahin doch sehr große Burschenschaft.
          <br />
          <br />
          An den Grenzgängen 1900 und 1907 war keine Burschenschaft Balbach
          vertreten. Erst 1914 gründete sie sich neu und hatte 70 Burschen und 3
          Führer.
          <br />
          <br />
          Wegen des ausbrechenden 1. Weltkrieges ging dieser Grenzgang nicht
          "raus". Die letzte Mark aus der Burschenschaftskasse der
          Burschenschaft Balbach von 1914 wurde zur Erinnerung in die
          Fahnenspitze der Burschenschaftsfahne eingefasst und ziert auch heute
          noch die neue Fahne von 1991.
          <br />
          <br />
          In den Grenzgängen 1928, 1935 und ab 1950 gab es in der Hainstraße nur
          eine Burschenschaft, die Balbach. Sie hatte somit die Nachfolge der
          beiden anderen Burschenschaften angetreten. Dies wird ganz besonders
          durch die Übernahme des einen Fahnenmotivs der Burschenschaft Johann
          Peter Unkel, der Seite mit dem Gambrinus, dokumentiert. <br />
          <br />
          (Verfasst von Martin Waid, 2. Führer 1984, anlässlich des
          hundertjährigen Jubiläums der Burschenschaft Balbach in 1994.)
        </p>
      </div>
    );
  }
}

export default Geschichte;
