import schleife1914 from "../assets/schleife1914.png";
import schleife1928 from "../assets/schleife1928.png";
import schleife1935 from "../assets/schleife1935.png";
import schleife1950 from "../assets/schleife1950.png";
import schleife1956 from "../assets/schleife1956.png";
import schleife1963 from "../assets/schleife1963.png";
import schleife1970 from "../assets/schleife1970.png";
import schleife1977 from "../assets/schleife1977.png";
import schleife1984 from "../assets/schleife1984.png";
import schleife1991 from "../assets/schleife1991.png";
import schleife1998 from "../assets/schleife1998.png";
import schleife2005 from "../assets/schleife2005.png";
import schleife2012 from "../assets/Schleife-Aktuell.png";

let history = [
  {
    heading: "Grenzgang 1894",
    image: null,
    inPersona: [
      {
        position: "Adjutanten des Burschenoberst",
        names: ["Adolf Schmidt", "Wilhelm Braun"]
      },
      {
        position: "Führer",
        names: ["Louis Plitt", "Georg Wehn"]
      },
      {
        position: "Reiter",
        names: ["Fritz Balbach", "Willy Breidenstein", "Otto Achenbach"]
      },
      {
        position: "Vorsitzender",
        names: ["Wilhelm Plitt"]
      },
      {
        position: "Schriftführer",
        names: ["Karl Wehn"]
      },
      {
        position: "Fahnenträger",
        names: ["Ferdinant Gerhard", "Karl Weigel", "Heinrich Wild"]
      }
    ]
  },
  {
    heading: "Grenzgang 1914",
    image: schleife1914,
    inPersona: [
      {
        position: "Adjutant des Burschenhauptmanns",
        names: ["Karl Wambach"]
      },
      {
        position: "Führer",
        names: ["Georg Kleinhenn", "Hermann Jäger", "Adolf Schmidt"]
      },
      {
        position: "Reiter",
        names: [
          "Fritz Balbach",
          "Willy Breidenstein",
          "Otto Achenbach",
          "Karl Unverzagt",
          "Paul Hause",
          "Otto Maibohm"
        ]
      },
      {
        position: "Rechner",
        names: ["Herbert Gawlick", "Ernst Köppe"]
      },
      {
        position: "Schriftführer",
        names: ["Jakob Klingemeier"]
      },
      {
        position: "Fahnenträger",
        names: ["Leo Fischer", "Albert Koch", "Josef Steinhauer"]
      },
      {
        position: "Wirt",
        names: ["Fritz Wenk"]
      }
    ]
  },
  {
    heading: "Grenzgang 1928",
    image: schleife1928,
    inPersona: [
      {
        position: "Führer",
        names: ["Hans Fischer", "Karl Unverzagt", "Otto Schmidt"]
      },
      {
        position: "Reiter",
        names: ["Hugo Junker", "Otto Steber", "Otto Gercke", "Emil Hacker"]
      },
      {
        position: "Rechner",
        names: ["Leo Behrend", "Leo Fischer"]
      },
      {
        position: "Schriftführer",
        names: ["Jakob Debus", "Ludwig Krug"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Otto Knapp",
          "Otto Barts",
          "Albert Genrich",
          "Karl Gessner",
          "Fritz Bastert",
          "Willi Grösser",
          "Hermann Born",
          "Sigismund Prentzel",
          "Otto Wehrmann"
        ]
      },
      {
        position: "Wirt",
        names: ["Fritz Nieland"]
      }
    ]
  },
  {
    heading: "Grenzgang 1935",
    image: schleife1935,
    inPersona: [
      {
        position: "Burschenhauptmann",
        names: ["Otto Schmidt"]
      },
      {
        position: "Adjutant des Burschenhauptmanns",
        names: ["Heinz Bielefeld"]
      },
      {
        position: "Führer",
        names: ["Karl Gessner", "Otto Kraft", "Otto Wehrmann"]
      },
      {
        position: "Reiter",
        names: ["Heini Staab", "Gustav Nasemann"]
      },
      {
        position: "Alterspräsident",
        names: ["Jacob Debus"]
      },
      {
        position: "Rechner",
        names: ["Otto Vogt", "Hugo Seip"]
      },
      {
        position: "Schriftführer",
        names: ["Ernst Battenberg", "Willi Waid"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Karl Kraft",
          "Fritz Bastert",
          "Heinrich Wiegand",
          "Hermann Reibert",
          "Dreher",
          "Willi Fischbach",
          "Heinz Bartels",
          "Strauch",
          "Schneider"
        ]
      }
    ]
  },
  {
    heading: "Grenzgang 1950",
    image: schleife1950,
    inPersona: [
      {
        position: "Burschenhauptmann",
        names: ["Ernst Cyriax"]
      },
      {
        position: "Führer",
        names: ["Otto Gercke", "Hans Zierke"]
      },
      {
        position: "Reiter",
        names: ["Alfred Benner"]
      },
      {
        position: "Alterspräsident",
        names: ["Ernst Cyriax"]
      },
      {
        position: "Rechner",
        names: ["Walter Schellberg"]
      },
      {
        position: "Schriftführer",
        names: ["August Dreher", "Horst Glaubrecht"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Kurt Benzin",
          "Willi Gilbert",
          "Kurt Gillat",
          "Erich Heck",
          "Rudi Wagner",
          "Jakob Busmann",
          "Ernst Krug",
          "Alfred Wille",
          "Klaus Habich"
        ]
      },
      {
        position: "Seidippeträger",
        names: ["Horst Benzin", "Friedhelm Habich", "Gerhard Benner"]
      },
      {
        position: "Wirt",
        names: ["Otto Gercke"]
      }
    ]
  },
  {
    heading: "Grenzgang 1956",
    image: schleife1956,
    inPersona: [
      {
        position: "Burschenhauptmann",
        names: ["Klaus Achenbach"]
      },
      {
        position: "Führer",
        names: ["Horst Kauer", "Hajo Groh"]
      },
      {
        position: "Reiter",
        names: ["Giso Leinweber"]
      },
      {
        position: "Alterspräsident",
        names: ["August Dreher"]
      },
      {
        position: "Rechner",
        names: ["Siegfried Knappmann", "Karl-Heinz Kraft"]
      },
      {
        position: "Schriftführer",
        names: ["Hans Hütter", "Kurt Benzin"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Georg Bernhardt",
          "Erich Petermann",
          "Alfred Benner",
          "Bernd Liebetrau",
          "Bernd Debus",
          "Jürgen Bernhard",
          "Günther Einolf",
          "Karl-Heinz Kappes",
          "Helmut Reitz"
        ]
      },
      {
        position: "Wirt",
        names: ["Sepp Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1963",
    image: schleife1963,
    inPersona: [
      {
        position: "Burschenoberst",
        names: ["Klaus Achenbach"]
      },
      {
        position: "Führer",
        names: ["Hajo Groh", "Gustav Herrmann", "Dieter Benzin"]
      },
      {
        position: "Reiter",
        names: ["Alfred Benner"]
      },
      {
        position: "Alterspräsident",
        names: ["August Dreher"]
      },
      {
        position: "Rechner",
        names: ["Bernhard Debus", "Egon Manz"]
      },
      {
        position: "Schriftführer",
        names: ["Thomas Wilke", "Jochen Bielefeld"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Jürgen Appel",
          "Giso Leinweber",
          "Walter Kraflage",
          "Bernd Liebetrau",
          "Heinz Liebetrau",
          "Werner Herrmann",
          "Bernd Plessl",
          "Lothar Wehn",
          "Jürgen Lenski"
        ]
      },
      {
        position: "Wirt",
        names: ["Sepp Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1970",
    image: schleife1970,
    inPersona: [
      {
        position: "Führer",
        names: ["Lothar Wehn", "Helmut Kraft", "Eckhard Debus"]
      },
      {
        position: "Alterspräsident",
        names: ["Erich Weidemann"]
      },
      {
        position: "Rechner",
        names: ["Karl-Immo Peter", "Eugen Wild"]
      },
      {
        position: "Schriftführer",
        names: ["Eckhard Debus", "Wolfgang Bergmann"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Klaus Theofel",
          "Robert Bermann",
          "Günther Wasner",
          "Michael Geidis",
          "Eugen Wild",
          'Klein "Mimü"',
          "Feuring",
          "Schmidt",
          "Wolfgang Bergmann"
        ]
      },
      {
        position: "Seidippeträger",
        names: ["Plack", "Till", "Klein"]
      },
      {
        position: "Wirt",
        names: ["Sepp Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1977",
    image: schleife1977,
    inPersona: [
      {
        position: "Führer",
        names: ["Uli Haydn", "Reinhard Knapp", "Christian Waid"]
      },
      {
        position: "Alterspräsident",
        names: ["Erich Weidemann"]
      },
      {
        position: "Rechner",
        names: ["Klaus Theofel", "Kai Gercke", "Uli Schneider"]
      },
      {
        position: "Schriftführer",
        names: ["Günther Wasner", "Hartmut Schmidt"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Günther Wasner",
          "Eugen Wild",
          "Jürgen Roemer",
          "Dieter Lichtenthäler",
          "Stefan Weis",
          "Martin Waid",
          "Wolfgang Bartoschick",
          "Peter Neumann",
          "Mathias Haydn"
        ]
      },
      {
        position: "Wirt",
        names: ["Sepp Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1984",
    image: schleife1984,
    inPersona: [
      {
        position: "Adjutanten des Burschenoberst",
        names: ["Thomas Denkler", "Alfred Immel"]
      },
      {
        position: "Führer",
        names: ["Jürgen Roemer", "Martin Waid", "Christian Waid"]
      },
      {
        position: "Reiter",
        names: ["Martin Kraft"]
      },
      {
        position: "Alterspräsident",
        names: ["Dieter Benzin"]
      },
      {
        position: "Rechner",
        names: ["Uli Schneider", "Hans Till", "Jürgen Zimmermann"]
      },
      {
        position: "Schriftführer",
        names: ["Ingo Schwerdt", "Günther Wasner"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Dieter Lichtenthäler",
          "Volkhard Ferchland",
          "Peter Neumann",
          "Michael Roemer",
          "Jörg Mauer",
          "Holger Meyer",
          "Norbert Pfeil",
          "Stefan Schmidt",
          "Detlef Meyer"
        ]
      },
      {
        position: "Seidippeträger",
        names: ["Ingo Herrmann", "Jens Gemmecke", "Clemens Nau"]
      },
      {
        position: "Wirt",
        names: ["Peter Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1991",
    image: schleife1991,
    inPersona: [
      {
        position: "Burschenoberst",
        names: ["Martin Kraft"]
      },
      {
        position: "Adjutant des Burschenoberst",
        names: ["Norbert Pfeil"]
      },
      {
        position: "Wettläufer",
        names: ["Michael Roemer"]
      },
      {
        position: "Führer",
        names: ["Uwe Fischer", "Stefan Weis", "Uwe Metzger", "Thomas Schmidt"]
      },
      {
        position: "Reiter",
        names: ["Albrecht Welz"]
      },
      {
        position: "Alterspräsident",
        names: ["Günther Wasner"]
      },
      {
        position: "Rechner",
        names: ["Jens Hilberseimer", "Jürgen Zimmermann", "Dietrich Schneider"]
      },
      {
        position: "Schriftführer",
        names: ["Ingo Schwerdt", "Thomas Michel"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Dieter Lichtenthäler",
          "Holger Meyer",
          "Martin Waid",
          "Michael Donges",
          "Thomas Böhle",
          "Christian Groh",
          "Frank Benner",
          "Uwe Kraft",
          "Peter Fischer"
        ]
      },
      {
        position: "Seidippeträger",
        names: [
          "Christian Dreher",
          "Lars Gemmecke",
          "Sven Simmer",
          "Dieter Schwammborn",
          "Tim Lichtenthäler",
          "Stefan Debus"
        ]
      },
      {
        position: "Wirt",
        names: ["Peter Fischer"]
      }
    ]
  },
  {
    heading: "Grenzgang 1998",
    image: schleife1998,
    inPersona: [
      {
        position: "Burschenoberst",
        names: ["Uwe Kraft"]
      },
      {
        position: "Adjutant des Burschenoberst",
        names: ["Martin Kraft"]
      },
      {
        position: "Wettläufer",
        names: ["Thomas Böhle"]
      },
      {
        position: "Führer",
        names: ["Christian Groh", "Sven Kunkler", "Swen Genz", "Ingo Herrmann"]
      },
      {
        position: "Reiter",
        names: ["Alexander Acker"]
      },
      {
        position: "Alterspräsident",
        names: ["Ulrich Fleischer"]
      },
      {
        position: "Rechner",
        names: ["Dietrich Schneider", "Jens Oliver Schmidt", "Karsten Plitt"]
      },
      {
        position: "Schriftführer",
        names: ["Thomas Michel", "Stefan Gaschler"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Thomas Schmidt",
          "Peter Fischer",
          "Tim Lichtenthäler",
          "Jörg Hummer",
          "Björn Happel",
          "Boris Weber",
          "Stefan Debus",
          "Carsten Finke",
          "Uwe Simon"
        ]
      },
      {
        position: "Seidippeträger",
        names: [
          "Peter Debus",
          "Manuel Werner",
          "Christian Kunkel",
          "Martin Andreas",
          "Thomas Bustcher",
          "Rainer Kunkel"
        ]
      },
      {
        position: "Wirt",
        names: ["Heinz Hamata"]
      }
    ]
  },
  {
    heading: "Grenzgang 2005",
    image: schleife2005,
    inPersona: [
      {
        position: "Burschenhauptmann",
        names: ["Swen Genz"]
      },
      {
        position: "Adjutant des Burschenhauptmann",
        names: ["Jens Oliver Schmidt"]
      },
      {
        position: "Führer",
        names: [
          "Tim Lichtenthäler",
          "Christian Kunkel",
          "Jörg Hummel",
          "Thomas Schmidt"
        ]
      },
      {
        position: "Reiter",
        names: ["Martin Kraft", "Alexander Acker"]
      },
      {
        position: "Alterspräsident",
        names: ["Ulrich Fleischer"]
      },
      {
        position: "Rechner",
        names: ["Andreas Lalla", "Carsten G. Herrmann", "Roland Roemer"]
      },
      {
        position: "Schriftführer",
        names: ["Jens Kirchhainer", "Ingo Herrmann"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Christian Groh",
          "Thomas Böhle",
          "Stefan Debus",
          "Sascha Wolf",
          "Peter Debus",
          "Philipp Wiemers",
          "Dietrich Schneider",
          "Timo Mangner",
          "Georg Zindel"
        ]
      },
      {
        position: "Seidippeträger",
        names: [
          "Christian Achenbach",
          "Christian Cramer",
          "Tim Denkler",
          "Felix Scholz",
          "Max Waid",
          "Felix Wiemers"
        ]
      },
      {
        position: "Fassmeister",
        names: ["Markus Moog", "Peter Debus", "Rainer Künkel"]
      },
      {
        position: "Wirt",
        names: ["Guiseppe"]
      }
    ]
  },
  {
    heading: "Grenzgang 2012",
    image: schleife2012,
    inPersona: [
      {
        position: "Adjutant des Burschenoberst",
        names: ["Felix Michel"]
      },
      {
        position: "Wettläufer",
        names: ['Karsten "Kohle" Plitt']
      },
      {
        position: "Führer",
        names: ["Tim Denkler", "Max Waid", "Rainer Kunkel", "Jens Kreimeyer"]
      },
      {
        position: "Reiter",
        names: ["Robin Ruf", "Tim Lichtenthäler"]
      },
      {
        position: "Alterspräsident",
        names: ["Jens Oliver Schmidt"]
      },
      {
        position: "Rechner",
        names: ["Felix Scholz", "Carsten G. Herrmann", "Christian Achenbach"]
      },
      {
        position: "Schriftführer",
        names: ["Carl Philip Bolldorf", "Christian Kunkel", "Max Plum"]
      },
      {
        position: "Mädchenführer",
        names: ["Jens Kirchhainer"]
      },
      {
        position: "Fahnenträger",
        names: [
          "Felix Wiemers",
          "Jens Oliver Schmidt",
          "Peter Debus",
          "Jonas Bodenbender",
          "Danilo Stubenrauch",
          "Papayaw Afriyie",
          "Timo Mangner",
          "Thorsten Bönsch",
          "Jörg Hummel"
        ]
      },
      {
        position: "Fassmeister",
        names: ["Christian Cramer", "Peter Debus", "Philipp Wiemers"]
      },
      {
        position: "Gastreiter",
        names: ["Christoph Freiling (BS Oberstadt)"]
      },
      {
        position: "Wirt",
        names: ["Peter Fischer jr."]
      }
    ]
  }
];

export { history };
