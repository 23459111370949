import React from "react";

export default React.createContext({
  token: null,
  proToken: null,
  login: () => {},
  logout: () => {},
  adminLogin: () => {},
  adminLogout: () => {}
});
