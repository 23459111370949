import React, { Component } from "react";
import { Linklist1, Linklist2 } from "../Linklists";
import "./toggledmenu.css";

export class ToggledMenu extends Component {
  render() {
    let propName;
    if (this.props.visible) {
      propName = "toggled-menu";
    } else {
      propName = "hidden-menu";
    }
    return (
      <div className={propName}>
        <Linklist1
          closeMenu={() => {
            this.props.closeMenu();
          }}
        />
        <Linklist2
          closeMenu={() => {
            this.props.closeMenu();
          }}
        />
      </div>
    );
  }
}

export default ToggledMenu;
