import React, { Component } from "react";
import { lyriklist } from "./lyrik-list";
import LyrikItem from "./LyrikItem";
import "../Newsfeed/news.css";

export class Lyrik extends Component {
  render() {
    return (
      <section className="news">
        <div className="cut-line" />
        <h4 className="news-heading">Lyrik und Poesie</h4>
        <div className="news-list poesi">
          {lyriklist.map((lyrikitem, i) => (
            <LyrikItem key={i} lyrik={lyrikitem} />
          ))}
        </div>
      </section>
    );
  }
}

export default Lyrik;
