import React, { Component } from "react";
import {
  compareNames,
  compareNegativeNames,
  compareLiters,
  compareNegativeLiters,
} from "./helpers";
import authContext from "../context/auth-context";

export class BalbinenIntern extends Component {
  state = {
    balbinen: [],
    openUpdate: null,
  };

  constructor(props) {
    super(props);
    this.currentLiter = React.createRef();
  }

  static contextType = authContext;

  componentWillMount() {
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                        query {
                            getBalbinen {
                                _id
                                firstName
                                lastName
                                openLiters
                            }
                        }
                    `,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((resData) => {
        this.setState({ balbinen: resData.data.getBalbinen });
      })
      .catch((err) => {
        //console.log(err);
      });
  }

  openUpdate = (id) => {
    this.setState({ openUpdate: id });
  };

  srtbby = (a, b) => {
    const { balbinen } = this.state;
    if (a === 1 && b === "Name") {
      this.setState({ balbinen: balbinen.sort(compareNames) });
    } else if (a === 0 && b === "Name") {
      this.setState({ balbinen: balbinen.sort(compareNegativeNames) });
    } else if (a === 1 && b === "Litern") {
      this.setState({ balbinen: balbinen.sort(compareLiters) });
    } else if (a === 0 && b === "Litern") {
      this.setState({ balbinen: balbinen.sort(compareNegativeLiters) });
    }
  };

  update = (id) => {
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      body: JSON.stringify({
        query: `
                    mutation {
                        updateLitersBalbine(_id: "${id}", liter: ${this.currentLiter.current.value}) {
                        firstName
                        }
                    }
                `,
      }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((res) => res.json())
      .then(window.location.reload.bind(window.location))
      .catch((err) => {
        //console.log(err)
      });
  };

  render() {
    const { visible, nameSymb, literSymb } = this.props;
    const { balbinen } = this.state;
    if (visible) {
      return (
        <div className="intern-component">
          <p className="iban">IBAN Balbinen: DE10 5176 2434 0000 6631 07</p>
          <div className="list-oL">
            <div className="sortBy">
              <p>Name</p>
              <button onClick={() => this.props.sortBy("Balbine", "Name")}>
                {nameSymb}
              </button>
            </div>
            <div className="sortBy">
              <button onClick={() => this.props.sortBy("Balbine", "Litern")}>
                {literSymb}
              </button>
              <p>Offene Literzahl</p>
            </div>
          </div>
          {balbinen.map((balbine, i) => (
            <div className="list-oL" key={i}>
              <div className="flex names">
                <p>{balbine.firstName}</p>
                <p>{balbine.lastName}</p>
              </div>
              {(!this.state.openUpdate ||
                this.state.openUpdate !== balbine._id) && (
                <div className="flex">
                  <p>{balbine.openLiters} l</p>
                  {this.context.proToken && (
                    <button
                      className="flex-btn"
                      onClick={() => this.openUpdate(balbine._id)}
                    >
                      ✎
                    </button>
                  )}
                </div>
              )}
              {this.state.openUpdate === balbine._id && (
                <div className="flex">
                  <input
                    type="number"
                    placeholder="Neue l angeben"
                    id="smallerInput"
                    ref={this.currentLiter}
                  />
                  {this.context.proToken !== "null" && (
                    <button
                      className="flex-btn"
                      onClick={() => this.update(balbine._id)}
                    >
                      ✓
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default BalbinenIntern;
