import React, { Component } from "react";
import "./contact.css";
import { validate } from "../helpers";

export class Contact extends Component {
  constructor(props) {
    super(props);
    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
    this.message = React.createRef();
  }

  state = {
    tooShort: false,
    wrongMail: false,
    daccord: false,
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.message.current.value.length === 0) {
      this.setState({ tooShort: true, wrongMail: false });
      return;
    }
    if (validate(this.email.current.value) === false) {
      this.setState({ wrongMail: true, tooShort: false });
      return;
    }
    let text = this.message.current.value.replace(/\n\r?/g, "<br />");
    fetch(`${process.env.REACT_APP_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `mutation {
            newMail(input: {
              firstName: "${this.firstName.current.value}"
              lastName: "${this.lastName.current.value}"
              email: "${this.email.current.value}"
              message: """${text}"""
            })
            {
              firstName
            }
          }
        `,
      }),
    })
      .then((res) => res.json())
      .then((resData) => {
        this.setState({ tooShort: false, wrongMail: false });
        this.firstName.current.value = "";
        this.lastName.current.value = "";
        this.email.current.value = "";
        this.message.current.value = "";
        this.setState({ daccord: true });
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  render() {
    const { daccord, tooShort, wrongMail } = this.state;
    return (
      <section className="contact">
        <div className="cut-line" />
        <h4 className="news-heading">Kontakt</h4>
        <p className="contact-info">
          Kontaktiere uns bei Fragen, Anregungen und Vorschläge aller Art...
        </p>
        <form className="balbach-contact">
          <div className="inputs">
            <input
              type="text"
              id="firstName"
              name="firstname"
              placeholder="Vorname"
              ref={this.firstName}
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
            <input
              type="text"
              id="lastName"
              name="lastname"
              placeholder="Nachname"
              ref={this.lastName}
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
            <input
              type="email"
              id="email-adress"
              name="email-adress"
              placeholder="E-Mail Adresse"
              ref={this.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              required
            />
          </div>

          <textarea
            id="subject"
            name="subject"
            ref={this.message}
            onChange={(e) => this.setState({ message: e.target.value })}
            placeholder="Deine Nachricht an uns..."
          />
          {tooShort && (
            <p className="errorMsg">Bitte geben Sie eine Nachricht ein.</p>
          )}
          {wrongMail && (
            <p className="errorMsg">
              Bitte geben Sie eine gültige E-Mail-Adresse ein.
            </p>
          )}
          <input
            onClick={(e) => this.handleFormSubmit(e)}
            className="contact-submit"
            type="submit"
            value="Absenden"
          />
          {daccord && (
            <p className="okMsg">
              Vielen Dank, ihre Nachricht ist auf dem Weg zu uns!
            </p>
          )}
        </form>
      </section>
    );
  }
}

export default Contact;
