import React, { Component } from "react";
import "../../index.css";
import "./welcome.css";

export class Welcome extends Component {
  render() {
    return (
      <div>
        <h1 className="welcome-header">Burschenschaft Balbach</h1>
        <p className="welcome-subheading">
          Der Stein - Die Grenze - in Ewigkeit
        </p>
      </div>
    );
  }
}

export default Welcome;
