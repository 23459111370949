import React, { Component } from "react";
import "../Body/body.css";

import Welcome from "../Body/Welcome-Area/Welcome";
import Newsfeed from "../Body/Newsfeed/Newsfeed";
import Contact from "../Body/Contact/Contact";
import Lyrik from "../Body/Lyrik/Lyrik";

export class Start extends Component {
  render() {
    return (
      <div>
        <Welcome />
        <Newsfeed />
        <Lyrik />
        <Contact />
      </div>
    );
  }
}

export default Start;
