import React, { Component } from "react";
import "../../Routes/start.css";
import { newslist } from "./news-list";
import News from "./News";

export class Newsfeed extends Component {
  render() {
    return (
      <section className="news">
        <div className="cut-line" />
        <h4 className="news-heading">Neuigkeiten</h4>
        <div className="hide-it">
          <div className="news-list">
            {newslist.map((newitem, i) => (
              <News key={i} news={newitem} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Newsfeed;
