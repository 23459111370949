import React, { Component } from "react";
import "./countdown.css";
import Countdown from "react-countdown-now";

export class CountDown extends Component {
  render() {
    return (
      <div className="countdown">
        <Countdown
          className="countdown-self"
          date={"Thu, 13 Aug 2026 07:00:00"}
        />
      </div>
    );
  }
}

export default CountDown;
