import React from "react";
import ReactDOM from "react-dom";
import "./assets/fonts/Amatic_SC/AmaticSC-Regular.ttf";
import "./assets/fonts/Assistant/Assistant-Regular.ttf";
import "./assets/fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.ttf";
import "./assets/fonts/Raleway/Raleway-Regular.ttf";
import "./index.css";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));
