import Fahne from "../../assets/fahnentraeger1.jpeg";
import Liebetrau from "../../assets/fuehrer.jpeg";
import Fruehschoppen from "../../assets/fruehschoppen.jpeg";
import Geburtstag from "../../assets/geburtstag.jpeg";
import Tauziehen from "../../assets/tauziehen.jpg";
import Fußball from "../../assets/fussball.jpeg";
import FußballBalbinen from "../../assets/fussball2.jpg";
import Schleifenueberreichung from "../../assets/schleifenueberreichung.jpg";
import Jahreswechsel from "../../assets/image.jpg";
import Burschenball from "../../assets/burschenball.jpg";
import CoronaBild from "../../assets/balbach-bild.jpg";
import HainstrasseFrauen from "../../assets/hainstrasse_frauen.jpg";
import Sommerfest from "../../assets/sommerfest21.jpg";

let newslist = [
  {
    image: HainstrasseFrauen,
    title: "Balbinenvorstand organisiert Aperol-Spritz Verköstigung",
    firstPart:
      "Die Frauen der MG Hainstrasse starteten am Samstag, den 28.08.2021 eine gemeinsame Wanderung nach Mornshausen.",
    text: `Als Zwischenstopp und Überraschung für die Frauen, organisierte der Balbinenvorstand bei der 4. Führerin Marlene eine kleine Aperol-Verköstigung.

Sowohl die Balbinen, als auch die Frauen, freuten sich über das Wiedersehen und sogar der Grenzgangswalzer wurde singend zum Besten gegeben.
    
Als abschließendes Dankeschön, sammelten die Frauen einige Liter für die Balbinen Kasse.
    
An dieser Stelle nochmal ganz lieben Dank an Euch und ein kräftiges Hallelujah! 
    
Es war uns eine Freude.`,
  },
  {
    image: Sommerfest,
    title: "Sommerfest am Dappesboden",
    firstPart:
      "Am Samstag, den 07.08.2021 fand seit langer Zeit endlich wieder unser Balbach-Sommerfest statt.",
    text: `Trotz der GGG-Regularien (Geimpft, Genesen, Getestet) und entsprechenden Nachweisen, die aufgrund der Corona-Pandemie vorgezeigt werden mussten, meldeten die Balbinen und Burschen dem Vorstand bereits im Vorfeld beachtliche 80 Anmeldungen.
    
Vorfreudig, durstig und gut gelaunt, startete bereits vormittags das Sommerfest mit einem zünftigen Frühschoppen und stimmungsvoller Blasmusik an unserem Waldplatz Dappesboden.
    
Bis in die frühen Morgenstunden feierte die balbach’sche Gemeinschaft und One-More-Time-Klänge schallten über unsere Heimatstadt.
    
Wir freuen uns schon jetzt auf das bevorstehende Kartoffelbraten im September.
    
Bis dahin, bleibt gesund und Horrido!`,
  },
  {
    image: CoronaBild,
    title: "Balbacher helfen Kunden der Biedenkopfer Tafel durch die Krise",
    firstPart:
      "Die Burschenschaft Balbach und die Balbinen helfen dabei, dass Betrieb der Biedenkopfer Tafel auch in Corona-Zeiten weiterlaufen kann.",
    text: `
Die Kunden der Tafel gehören zu jenen Menschen, die die Auswirkungen der Corona-Krise besonders beutelt: Schon wenn die Gesellschaft im "Normalbetrieb" läuft, ist es für sie aufgrund ihrer finanziellen Situation oftmals schwierig, über die Runden zu kommen.

Durch die Beschränkungen aufgrund der Pandemie verschärft sich ihre Lage nun zusätzlich. Denn die Tafeln, von denen die Menschen, die an der Schwelle zur Armut leben, ihre Lebensmittel beziehen, haben ihren Betrieb vorläufig eingestellt. "Unsere Mitarbeiter sind zum überwiegenden Teil Rentner, die ja selbst zur Risikogruppe einer Infektion gehören", erklärt Helmut Kretz, Vorsitzender der Biedenkopfer Tafel. Dass sie in dieser Zeit weiterarbeiteten, sei einfach nicht zu verantworten. Das betrifft sowohl die Ausgabe der Lebensmittel, wie auch das Abholen in den Märkten. "Das kriegen wir in der momentanen Lage logistisch einfach nicht geregelt", so Kretz.
    
Hinzu kommt, dass viele Märkte aber auch schlichtweg keine Lebensmittel mehr abzugeben haben - entweder, weil diese restlos ausverkauft sind oder weil die Marktleiter vereinzelt ihren Mitarbeitern als Dank für deren Leistungen Ware mit nach Hause geben, erzählt Kretz. Alleine die Biedenkopfer Tafel versorgt rund 800 Kunden - davon rund ein Drittel Kinder. Um die Betroffenen während dieser schwierigen Zeit nicht alleine zu lassen, haben die Tafel-Mitarbeiter nun eine einmalige Aktion gestartet: Mit Handschuhen und Mundschutz haben sie Care-Pakete für ihre Kunden gepackt. "Weil es ja derzeit keine frischen Waren gibt, mussten wir dabei auf unsere eisernen Reserven zurückgreifen", sagt Helmut Kretz.
Dabei handele es sich ausschließlich um haltbare Lebensmittel wie Marmelade, Reis, Nudeln oder Konserven, die über das Großlager in Wetzlar an die regionalen Tafeln verteilt werden. Aber auch aus der Rewe-Aktion "Fünf-Euro-Tüten" seien noch einige haltbare Lebensmittel im Bestand, die nun ausgegeben werden könnten. Bei der Verteilung der Care-Pakete hat die Tafel übrigens Unterstützung von den Mitgliedern der Burschenschaft Balbach sowie den Balbinen erhalten. Sie haben sich spontan bereit erklärt, die Ausgabe der Pakete zu übernehmen, um dadurch die betagten Tafel-Mitarbeier keiner zusätzlichen Gefahr auszusetzen. "Das ist natürlich eine unglaubliche Hilfe für uns", freut sich Helmut Kretz über die Initiative der Burschen und Mädchen.

Insgeheim hofft er darauf, dass vielleicht der eine oder andere von den Jugendlichen hängen bleibt und die Tafel auch künftig unterstützt. "Denn je länger die Einschränkungen des öffentlichen Lebens und die Kontaktverbote gelten, desto mehr befürchte ich, dass gerade unsere älteren Mitarbeiter vielleicht gar nicht mehr zurückkehren", erklärt Kretz. Für die Tafeln wäre das ein herber Verlust, weil jede Hilfe benötigt werde, um den wöchentlichen Betrieb am Laufen zu halten.

Um bei der Ausgabe der Care-Pakete Warteschlangen und das Aufeinandertreffen von zu vielen Personen zu vermeiden, haben die Tafel-Mitarbeiter die Kunden zuvor angerufen und mit diesen feste Zeiten vereinbart, wann sie ihre Tüten abholen konnten.

"Wir wollen mit dieser Aktion den hilfsbedürftigen Menschen in unserer Gesellschaft unsere Verbundenheit zeigen, auch wenn die Rahmenbedingungen derzeit schwierig sind", so Kretz.

Ob es bei einer einmaligen Aktion bleibt oder in Zukunft noch ein zweites Care-Paket gepackt werden kann, vermag er indes noch nicht zu sagen. "Das hängt im Wesentlichen ja auch von der Menge an haltbaren Lebensmitteln ab, die den Tafeln auch weiterhin zur Verfügung gestellt werden", betont Kretz. Derzeit sei jedenfalls noch nicht abzusehen, wann der reguläre Tafelbetrieb und damit auch das Abholen von Waren wieder aufgenommen werden könne.
    `,
  },
  {
    image: Burschenball,
    title: "Trinkfreudiger Stafettensieg für Balbinen und Burschen!",
    firstPart:
      "Der traditionelle und alle sieben Jahre stattfindende Burschenball liegt hinter uns. Gestartet wurde das Spektakel für die Wal-Freunde im Hauptquartier „Neue Krone“, wo das ein oder andere Kaltgetränk den Rachenraum befeuchtete und zugleich die Eintrittskarten verteilt wurden.",
    text: `
Anschließend ging es mit dem Spielmannszug samt Fackeln in die Richtung der Location: Die Eventhalle! 

Angekommen, platzierte man sich an den vorgesehenen Tischgruppierungen – hier waren unsere 128 Plätze allerdings nicht zu übersehen waren.

Nachdem die ein oder andere Rede der Offiziellen geschwungen, die Wettläufer ihre Peitschenklänge zum Besten gegeben haben und diverse Grenzgangslieder gesungen wurden, begann auch schon das lang ersehnte Trinkduell der Burschenschaften in Form einer Stafette. Das balbach’sche stilsichere Auftreten war zwar so schon kaum zu übertreffen, jedoch wurde der Spaß noch mit stilechten „Heute machen wir mal richtig einen“ – Lätzchen getoppt, sodass auch im Nachgang kein Outfit von Bierflüssigkeiten geziert wurde.

Schnell kristallisierte sich das Stafettenteam der Balbachburschen mit Führer Bodenbender, Fahnenträger Rakowski, Rechner Brunner und den beiden Fassmeistern Premium und Pauler, als unschlagbar heraus. In Sekundenschnelle leerten sie die Biergläser und wurden von der anwesenden Balbachschar für den glorreichen 1. Platz und den einhergehenden 30 Liter Bosch Pils Gutschein gefeiert.

Erstmals durften auch die Mädchen der Burschenschaften ihre Trinkfestigkeiten unter Beweis stellen und somit stellten natürlich auch die Balbinen ihre schnellsten Stafettentrinkerinnen samt Lätzchen auf. Das antretende Team, bestehend aus den Balbinen Katharina Bartl, Lena Gemmecke, Alisha Kienast, Laura Walther und der 2. Führerin Jana Wickenhöfer, sowie den Ersatzbalbinen Laura und Luisa Gemmecke, siegte und erreichte ebenfalls den 1. Platz im Stafetten-Wettbewerb. Glücklicherweise erhielten auch die Balbinen einen Gutschein über 20 Liter Bosch Pils. Demnach kann das nächste feierliche Dappesboden-Event definitiv kommen.

Nach all dem Trubel ging es trinkfreudig an den Theken, der Cocktailbar, und nicht zuletzt auch der Tanzfläche samt Liveband weiter. Auch „unsere Hymne“ des Grenzgangs 2019 durfte natürlich nicht fehlen und spätestens als „One more time“ erklang, stürmten fast alle Anwesenden der Balbach die Tanzfläche.

Sicher ist, wir haben einen unglaublich spaßigen und trinkbetonten Abend gemeinsam verbracht, der einen durchaus gelungenen Abschluss des Grenzgangs darstellt. Nun freuen wir uns alle auf die bevorstehenden Jahre mit hoffentlich genau so vielen Wal-Fisch-Flossen, köstlichen Bierfässchen und legendären „One more time“ – Momenten. 
    
„Wer will schon erwachsen sein? Eigentlich wollen wir doch alle nur saufen!“ 
(~ O-Ton Zitat eines Balbachburschen am Burschenball) 
    
In diesem Sinne, Prost und bis bald!`,
  },
  {
    image: Jahreswechsel,
    title: "Ein großartiges Jahr 2019 endet!",
    firstPart:
      "2019 ist Vergangenheit. Für die Balbach, sowie für alle Grenzgänger, geht ein sehr besonderes Jahr zu Ende.",
    text: `
Wir blicken zurück auf einen einzigartigen unbeschreiblichen Grenzgang, an dem ein Höhepunkt dem nächsten folgte. Der Vorstand, alle Balbinen und Burschen sind unendlich Dankbar für diese Zeit. Neben dem Grenzgang sind wir auch besonders Stolz auf unser 125-jähriges Jubiläum. Es war uns ein Fest!
Auch in der Zwischengrenzgangszeit werden wir die Balbach mit bestem Gewissen in die Zukunft führen.
Wir wünschen allen Grenzgängern, Balbinen, Burschen und Sympathisanten der Balbach ein erfolgreiches Jahr 2020!
    `,
  },
  {
    image: Schleifenueberreichung,
    title: "Eine atemberaubende Schleifenüberreichung liegt hinter uns!",
    firstPart:
      "Am Samstag, den 03.08.2019 kam es zu einem der spektakulärsten Momente in unserer bereits legendären gemeinsamen Vorgrenzgangszeit:",
    text: `
Die Überreichung der Schleife!

Bereits Wochen im Voraus trafen sich viele Balbinen innerhalb diverser Ausschüsse, um diesen Abend detailreich und mit viel Liebe vorzubereiten.
Der Abend stand unter dem Thema „Unter Wasser“, sodass nicht nur die Einladungen an die Burschen und Offiziellen dies widerspiegelten, sondern vor allem die Dekoration des Hütter Treff's dafür sorgte, dass man die Räumlichkeiten kaum wiedererkennen konnte. 
Unzählige von der Decke hängende Algen, leuchtende Heliumluftballons, große Kronleuchtern, viele Meeresbewohner samt unserem Jonas, stimmige Lichttechnik, eine Fotoecke und eine kreative Candybar, brachten die Gesichter von Balbinen und Burschen zum strahlen.
Nachdem ein einstimmender Sektempfang bei bestem Wetter im Außenbereich erfolgte, ging es rapide mit den Programmhighlights los.

Neben einstudierten Tanzeinlagen seitens der Balbinen, glänzten auch unser 2. Führer Torben Zaun und Bursche Luca Größer mit einer überraschenden und walrettenden Showeinlage. 
Auch der 2. Schriftführer Felix Scholz alias Bursche Goethe gab wieder passende Zeilen zum Besten. Er zeigte auf, dass die Balbach für Werte wie Toleranz, Freiheit und Freude am Leben einsteht.
Während die Offiziellen verschiedene Kompetenzen der Balbinen- und Burschenschar in einer Spielform erraten mussten, überragte unser Adju Michel mit stetig korrekten Antworten. 
Zudem ertönten zur Freude aller, die allseits bekannten Liedklänge des Burschenhauptmannes Daniel Weigl, sowie die seines Adjutanten Fabian Hoffmann. 
Äußerst fulminant waren auch die beiden Kurzfilme, welche von unserem Programmausschussteam geplant und von unserer Balbine Johanna Honndorf imposant erstellt worden waren.

Traditionsgemäß näherte sich schließlich der Höhepunkt des Abends und unsere jüngste Balbine Lea überreichte mit einem passenden Gedicht unsere Schleife. 
Die ein oder andere Träne zeigte sich auf den Wangen von  Balbinen und Burschen und der offizielle Teil wurde gemeinsam singend mit der „Alten Burschenherrlichkeit“ beschlossen.

Besonders stolz sind wir auf unsere drei Schleifengirls.
Die 4. Führerin Marlene Milchsack, Balbine Janina Weigl und Balbine Sarah Krill, die unsere Schleife entworfen und mit viel Mühe zeitintensiv gestickt haben. 
Ein unfassbar gigantisches und schönes Endergebnis habt ihr kreiert! 
Dafür nochmal ganz großes und waliges Dankeschön an Euch.

Besten Dank auch an dieser Stelle nochmal an unser großartiges Thekenteam, bestehend aus vielen lieben und fleißigen Altbalbinen und Altburschen, es war uns eine große Freude, dass Ihr uns so tatkräftig unterstützt habt und dabei wart.
 
Nicht zu vergessen natürlich auch einen ganz lieben Dank an alle Mitwirkenden des Abends, vor allem jedoch an die drei Schleifenüberreichungs-Ausschüsse und die einzelnen Balbinen, die zu der Vielfalt des Buffets beigetragen haben, ohne Euch wäre das alles nicht möglich gewesen.
 
Ein unvergesslicher, trinkfreudiger und spaßbetonter Abend liegt hinter uns, der mit Sicherheit allen lange in Erinnerung bleiben wird.
 
Der Wal – Die Grenze – In Ewigkeit !
    `,
  },
  {
    image: FußballBalbinen,
    title:
      "Balbinen verteidigen Titel zum dritten Mal in Folge und erreichen Turniersieg",
    firstPart:
      "Das diesjährige Mädchenfußballturnier war geprägt von sonnigem Wetter, mächtig viel Spaß und sichtlich sportlichen Mannschaften.",
    text: `Bereits viele Wochen zuvor, trainierten die Balbinen intensiv und motiviert mehrfach pro Woche im VFL Stadion. Die Fußballbalbinen, unter der Leitung ihrer beiden Coaches Schriftführer Christian „Giggi“ Achenbach und Fahnenträger Leo Metzger, kämpften beim Mädchenturnier erfolgreich bis zur letzten Minute. 
Während die „Balbinen-Ultras“ am Spielfeldrand den sportlich ambitionierten Balbinen so richtig mit Fangesängen einheizten, holte sich die Mannschaft bis zum letzten Spiel kein Gegentor ein und somit erreichten sie erneut das „zu Null“ Ergebnis.
Der gigantische Triple-Pokalsieg mutierte bis in die frühen Morgenstunden zu einer rauschenden Feier.

Außerdem möchten wir den Häschen ein großes Kompliment zum siegreichen 2. Platz aussprechen, sowie den Mädchen der Burschenschaft Billerbach zu einem glorreichen 3. Platz.
Vielen Dank für den unvergesslichen und fairen Turniertag an alle und viele Glückwünsche auch an die anderen Burschenschaften.
Ein riesiges Dankeschön geht an dieser Stelle auch nochmal an unsere Trikotsponsoren raus, sowie an die zahlreichen stimmungsbetonten Balbach-Fans und vor allem an unser überragendes Trainerteam.
     
Ihr seid der Wahnsinn. One more time!
     
In diesem Sinne…
„Eine Abwehr aus Granit,…“`,
  },
  {
    image: Fußball,
    title: "Balbach verpasst Turniererfolg knapp",
    firstPart:
      "Beim traditionellen Fußballturnier der Burschenschaften war das Ziel der Mannschaft von Coach Kunkel klar: es sollte der Titel sein.",
    text: `In einer Gruppe mit dem Hasenlauf und der Bach, konnten wir uns mit einem Unentschieden und einem Sieg als Erster durchsetzen. Mit Hilfe der Balbinen und deren kreativer Unterstützung bekam das Team noch Zusatzpunkte zugesprochen. 
    
Im Halbfinale konnte die Oberstadt souverän mit 3:1 besiegt werden. Trainer Kunkel wechselte sich hier gar selber ein, um einen fälligen Freistoß zu versenken und anschließend wieder den Platz zu verlassen. Episch! 

Es kam wie es kommen musste, im Finale ging es gegen die Billerbach. In einem offenen Schlagabtausch ging es hin und her. Nach der regulären Spielzeit und der Verlängerung stand es letztenndlich 2:2. Wie in 2012 musste das Elfmeterschießen entscheiden. Hier hatte die Billerbach leider die sichereren Schützen und gewann schließlich mit 5:3. 
Auch wenn diese Niederlage schmerzt, war es ein wunderbarer Tag mit den vereinigten Burschenschaften von Biedenkopf. 

Bis zum nächsten Turnier wird Adju Michel Einzeltraining im Elfmeterschießen bekommen ;-)`,
  },
  {
    image: Tauziehen,
    title: "Balbach holt sich den zweiten Platz beim Tauziehen!",
    firstPart:
      "Beim gestrigen Tauziehen der vereinigten Burschenschaften von Biedenkopf, wurde unser Team überraschend Zweiter.",
    text: `Im Röhrs Gründchen ließen unsere Burschen die Muskeln spielen und besiegten jede andere Burschenschaft, ehe sie im Finale gegen die Billerbach den Kürzeren zogen. 

Auch die Balbinen nahmen erstmalig an dieser Veranstaltung Teil und zeigten, dass sie nicht nur schön, sondern auch stark sind!`,
  },
  {
    image: Geburtstag,
    title: "Berauschende Geburtstagsfeier nach der 5.Versammlung",
    firstPart:
      'Am 16. Juni 1894 trafen sich Louis Plitt, Georg Wehn, Karl Zimmermann, Heinrich Wild und Karl Wehn in der "Balbachschen Wirtschaft", um eine Burschenschaft Balbach zu gründen.',
    text: `Genau 125 Jahre später traf sich die Balbach zu ihrer 5.Versammlung des Grenzgangs 2019.
    Dementsprechend festlich ging es in den Versammlungen der Burschen und Balbinen zu. Höhepunkt war das gemeinsame Singen um Punkt 24:00 Uhr. 
    
    In der Versammlung wurde mit Christian Achenbach der letzte freie Posten im Vorstand besetzt. Er wird nun neben seinem Amt als Fahnenträger auch den Posten des 3.Schriftführers ausfüllen.`,
  },
  {
    image: Fruehschoppen,
    title:
      'Traditioneller "Pfingstfrühschoppe" steigt wie der Phoenix aus der Asche',
    firstPart:
      "Nach einigen Jahren der Abstinenz konnte der traditionelle Frühschoppe am Pfingstmontag wieder in unserem Terminkalender etabliert werden.",
    text: "Gestern trafen sich ca. 40 Balbinen und Burschen im Hauptquartier „Neue Krone“. Es war mal wieder ein rundum gelungen Veranstaltung im Zeichen des Wals. Als Special-Guest gesellte sich Führer Neumann zu seiner heimlichen Liebe Balbach. Über diesen Besuch haben wir uns mal wieder besonders gefreut!",
  },
  {
    image: Fahne,
    title: "Fahnenträger des ersten Grenzangstages gewählt",
    firstPart:
      "Ebenfalls in der 4.Versammlung wurden das erste Fahnenträgertrio gewählt. \nDie Ehre die Fahne der Balbach den Kleeberg hinauf zu tragen haben Christian „Giggi“ Achenbach, Leo Metzger und Nico Pfeiffer.",
    text: "Bei unserem 125-jährigen Jubiläum durften sie sich bereits beweisen. ",
  },
  {
    image: Liebetrau,
    title: "Liebetrau komplementiert Führer-Quartett ",
    firstPart:
      "In der 4. Versammlung der Balbach stand mal wieder eine entscheidende Wahl auf dem Programm. Neben den Fahnenträgern des ersten Tages, und den Sargobmännern, wurde der 4.Führer der Balbach für den Grenzgang 2019 gewählt.",
    text: "Diesmal stellten sich gleich 5 Kandidaten zur Wahl. Als Sieger einer unglaublich spannenden Stichwahl ging Felix Liebetrau hervor. Der neu gewählte Führer Liebetrau nahm voller Stolz seinen Platz am Vorstandstisch ein. Wir wünschen auch ihm viel Erfolg bei den Aufgaben, die ihn erwarten.",
  },
];

export { newslist };
