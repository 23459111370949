import React, { Component } from "react";
import { IoIosMenu } from "react-icons/io";
import "./header.css";
import { Linklist1, Linklist2 } from "./Linklists";
import ToggledMenu from "./ToggledMenu/ToggledMenu";
import CountDown from "../Body/Countdown/Countdown";
import Logo from "../assets/balbach.png";

export class Header extends Component {
  state = {
    visibleMenu: false
  };

  render() {
    return (
      <nav>
        <div className="nav-section">
          <Linklist1 closeMenu={() => this.setState({ visibleMenu: false })} />
        </div>
        <img
          className="head-logo"
          src={Logo}
          alt="Das Logo der Burschenschaft Balbach"
        />
        <div className="countdown">
          <CountDown />
        </div>
        <button
          className="menu-toggle"
          onClick={() => {
            this.setState({ visibleMenu: !this.state.visibleMenu });
          }}
        >
          <IoIosMenu className="hello" size="2.5em" />
        </button>
        <div className="nav-sections">
          <Linklist2 closeMenu={() => this.setState({ visibleMenu: false })} />
        </div>
        <ToggledMenu visible={this.state.visibleMenu} />
      </nav>
    );
  }
}

export default Header;
