import React, { Component } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import AuthContext from "../context/auth-context";

export class Linklist1 extends Component {
  static contextType = AuthContext;

  render() {
    let locationString = window.location.href.split("/")[3];
    return (
      <div className="linklist1">
        {!this.context.token && !this.context.proToken && (
          <Link
            onClick={e =>
              locationString === ""
                ? e.preventDefault()
                : this.props.closeMenu()
            }
            className="heading-links"
            to="/"
          >
            Startseite
          </Link>
        )}
        {(this.context.token || this.context.proToken) && (
          <Link
            onClick={() => this.props.closeMenu()}
            className="heading-links"
            to="/balbach-intern"
          >
            Strafenseite
          </Link>
        )}
        <Link
          onClick={() => this.props.closeMenu()}
          className="heading-links"
          to="/amtstraeger-burschen"
        >
          Burschen
        </Link>
        <Link
          onClick={this.props.closeMenu}
          className="heading-links"
          to="/amtstraeger-balbinen"
        >
          Balbinen
        </Link>
      </div>
    );
  }
}

export class Linklist2 extends Component {
  static contextType = AuthContext;

  render() {
    return (
      <AuthContext.Consumer>
        {context => (
          <div className="linklist2">
            <Link
              onClick={() => this.props.closeMenu()}
              className="heading-links"
              to="/geschichte"
            >
              Geschichte
            </Link>
            <Link
              onClick={() => this.props.closeMenu()}
              className="heading-links"
              to="/historie"
            >
              Historie
            </Link>
            {!context.token && !context.proToken && (
              <Link
                onClick={() => this.props.closeMenu()}
                className="heading-links"
                to="/login"
              >
                LogIn
              </Link>
            )}
            {(context.token || context.proToken) && (
              <button
                onTouchEnd={() => this.props.closeMenu()}
                onClickCapture={() => this.props.closeMenu()}
                onClick={
                  context.token
                    ? () => context.logout()
                    : () => context.adminLogout()
                }
                className="heading-links logouter"
                to="/login"
              >
                Logout
              </button>
            )}
          </div>
        )}
      </AuthContext.Consumer>
    );
  }
}
