import React, { Component } from "react";
import "./App.css";
import Header from "./Header/Header.js";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";

import Start from "./Routes/Start";
import Burschen from "./Routes/Burschen";
import Balbinen from "./Routes/Balbinen";
import Geschichte from "./Routes/Geschichte";
import Historie from "./Routes/Historie";
import Login from "./Routes/Login";
import Footer from "./Body/Footer/Footer";
import AuthContext from "./context/auth-context";
import Intern from "./Routes/Intern";
import Datenschutz from "./Routes/Datenschutz";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${process.env.REACT_APP_URL}`,
});

class App extends Component {
  state = {
    token: null,
    proToken: null,
  };

  componentWillMount() {
    const reh_login_state = JSON.parse(localStorage.getItem("logInState"));
    this.setState(reh_login_state);
  }

  componentWillUnmount() {
    localStorage.setItem(
      "logInState",
      JSON.stringify({
        token: this.state.token,
        proToken: this.state.proToken,
      })
    );
  }

  login = (token) => {
    this.setState({ token }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: this.state.token,
          proToken: null,
        })
      );
    });
  };

  logout = () => {
    this.setState({ token: null }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: null,
          proToken: null,
        })
      );
    });
  };

  adminLogin = (proToken) => {
    this.setState({ proToken }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: null,
          proToken: this.state.proToken,
        })
      );
    });
  };

  adminLogout = () => {
    this.setState({ proToken: null }, () => {
      localStorage.setItem(
        "logInState",
        JSON.stringify({
          token: null,
          proToken: null,
        })
      );
    });
  };

  render() {
    return (
      <Router>
        <ApolloProvider client={client}>
          <AuthContext.Provider
            value={{
              token: this.state.token,
              proToken: this.state.proToken,
              login: this.login,
              logout: this.logout,
              adminLogin: this.adminLogin,
              adminLogout: this.adminLogout,
            }}
          >
            <div className="App">
              <Header />
            </div>
            <Switch>
              {!this.state.proToken && !this.state.token && (
                <Redirect from="/balbach-intern" to="/" exact />
              )}
              {(this.state.proToken || this.state.token) && (
                <Redirect from="/login" to="/balbach-intern" exact />
              )}
              <Route path="/" exact component={Start} />
              <Route path="/amtstraeger-burschen" component={Burschen} />
              <Route path="/amtstraeger-balbinen" component={Balbinen} />
              <Route path="/geschichte" component={Geschichte} />
              <Route path="/historie" component={Historie} />
              <Route path="/login" component={Login} />
              <Route path="/balbach-intern" component={Intern} />
              <Route path="/datenschutz" component={Datenschutz} />
            </Switch>
          </AuthContext.Provider>
          <Footer />
        </ApolloProvider>
      </Router>
    );
  }
}

export default App;
