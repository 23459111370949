import React, { Component } from "react";
import "./news.css";

export class News extends Component {
  render() {
    const { news } = this.props;
    return (
      <div className="single-news">
        <img className="news-image" src={news.image} alt="Artikelbild" />
        <section className="text-sec">
          <h4 className="news-title">{news.title}</h4>
          <p className="news-text">{news.firstPart}</p>
          <p className="news-text">{news.text}</p>
        </section>
      </div>
    );
  }
}

export default News;
